<template>
<KTCard class="border-0" body-class="pt-0">
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5 d-none d-sm-block">{{ $t('TRANSLATIONS.TITLE') }}</h5>
      <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200 d-none d-sm-block" />
      <div id="kt_subheader_search" class="d-flex align-items-center">
        <span id="kt_subheader_total" class="text-dark-50 font-weight-bold mr-5" />
        <form @submit.stop.prevent="">
          <b-input-group class="input-group input-group-sm input-group-solid" style="max-width: 175px">
            <InputForm
              id="kt_subheader_search_form"
              v-model="query.search"
              :placeholder="$t('BASE.SEARCH')"
              debounce="300"
              trim
              type="text"
            />
            <b-input-group-append>
              <span class="input-group-text">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/General/Search.svg" />
                </span>
              </span>
            </b-input-group-append>
          </b-input-group>
        </form>
      </div>
    </div>
  </template>
  <template #toolbar>
    <div v-permission="['edit_translations']" class="example-tools justify-content-center">
      <router-link :to="{ name: 'companyTranslationsCreate' }" class="btn btn-light-success font-weight-bold btn-sm">
        <span class="svg-icon btn-light-success">
          <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
        </span>
        {{ $t('TRANSLATIONS.NEW') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <b-card class="border-0" body-class="p-0">
      <b-tabs nav-class="nav nav-tabs nav-bold nav-tabs-line" content-class="mt-4" justified>
        <b-tab title="UA" active>
          <b-card-text>
            <locale-table :items="lang.ua" :busy="$loading" />
          </b-card-text>
        </b-tab>
        <b-tab title="EN">
          <b-card-text>
            <locale-table :items="lang.en" :busy="$loading" />
          </b-card-text>
        </b-tab>
        <b-tab title="RU">
          <b-card-text>
            <locale-table :items="lang.ru" :busy="$loading" />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </template>
</KTCard>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Repo from '@/core/repository/company/translationsRepository'
import InputForm from '@/components/forms-items/input'
import KTCard from '@/view/content/Card'
import LocaleTable from '@/components/translator/LocaleTable'

export default {
  name: 'CompanyTranslationsIndex',
  components: {
    KTCard,
    InputForm,
    LocaleTable,
  },
  data() {
    return {
      lang: {
        ua: [],
        en: [],
        ru: [],
      },
      query: {
        search: null,
      },
    }
  },
  watch: {
    query: {
      deep: true,
      handler() {
        this.load()
      },
    },
  },
  mounted() {
    this.load()
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('PAGE.TRANSLATIONS') }])
  },
  methods: {
    load() {
      this.loadingOn()
      Repo.list(this.query)
        .then((response) => {
          this.lang = response.data.payload
        })
        .catch(console.log)
        .finally(this.loadingOff)
    },
  },
}
</script>

<style scoped>

</style>
