<template>
<Table v-cloak :busy.sync="busy" :fields="fields" :items="items">
  <template #cell(name)="data">
    <div style="min-width: 150px">
      <span class="text-dark-75 font-weight-bolder font-size-lg mb-0">{{ data.item.name }}</span>
    </div>
  </template>
  <template #cell(actions)="data">
    <div style="min-width: 80px">
      <router-link v-permission="['edit_translations']"
                   :to="{ name: 'companyTranslationsEdit', params: { id: data.item.uuid }}"
                   class="btn btn-icon btn-light-info btn-sm mr-3"
      >
        <span class="svg-icon btn-light-primary">
          <inline-svg src="/media/svg/icons/Communication/Write.svg" />
        </span>
      </router-link>
      <div v-permission="['edit_translations']" class="btn btn-icon btn-light-danger btn-sm"
           @click="remove(data.item)"
      >
        <span class="svg-icon svg-icon-md btn-light-info">
          <inline-svg src="/media/svg/icons/Home/Trash.svg" />
        </span>
      </div>
    </div>
  </template>
</Table>
</template>

<script>
import Table from '@/components/UI/Table'
import Repo from '@/core/repository/company/translationsRepository'

export default {
  name: 'LocaleTable',
  components: {
    Table,
  },
  props: {
    busy: {
      type: Boolean,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'key',
          label: this.$t('TRANSLATIONS.KEY'),
          sortable: false,
        },
        {
          key: 'text',
          label: this.$t('TRANSLATIONS.TEXT'),
          sortable: false,
        },
        {
          key: 'actions',
          label: this.$t('BASE.ACTIONS'),
          class: 'text-right',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    remove(ent) {
      this.$confirmAction()
        .then(this.loadingOn)
        .then(() => {
          Repo.remove(ent)
            .then(this.$toastSuccess('TOAST.SUCCESS'))
            .then(this.load)
            .catch(this.$toastSuccess('TOAST.DANGER'))
            .finally(this.loadingOff)
        })
    },

  },
}
</script>

<style scoped>

</style>
